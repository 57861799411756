import React, { useContext, useState, useEffect } from 'react';
import { Box, Input } from '../../Core';
import { Col, Row } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { updateQueryParam } from '../../../utils/helperFn';
import GlobalContext from '../../../context/GlobalContext';
import BigRoundButton from '../../BigRoundButton';
import lockIcon from '../../../assets/image/svg/lock.svg';
import { navigate } from 'gatsby';
import sha1 from 'crypto-js/sha1';
import hex from 'crypto-js/enc-hex';
import {
  createLeadContactBillingAddressPayload,
  extractLeadIdFromAPIResponse,
  extractContactIdFromAPIResponse,
  extractAddressIdFromAPIResponse,
} from '../../../utils/OdinOrderHelpers';
import TSAPI from '../../../api';
import { TinySwitch } from '../../../components/Core/Switch';
import { emailRegex } from '../../../utils/helperFn';
import API from '../../../api/api';
import { prepareProductForLeadUpdate } from '../../../utils/OdinOrderHelpers';
import { OdinProductSchema } from '../../../utils/OdinProductSchema';
import { isForbiddenNumber } from '../../../utils/helperFn';

const ResidentialForm = () => {
  const gContext: any = useContext(GlobalContext);
  const [isCreatingRecords, setIsCreatingRecords] = useState<boolean>(false);
  const [marketingConsent, setMarketingConsent] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [validPhoneNumber, setValidPhoneNumber] = useState(false)
  const [phoneChecked, setPhoneChecked] = useState(false)
  const [validEmailAddress, setValidEmailAddress] = useState(true) // false
  const [emailChecked, setEmailChecked] = useState(true) // false
  let leadContactBillingAddress = []

  const axios = require('axios');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    updateQueryParam('step', '3')
  }, []);

  useEffect(() => {
    if (validPhoneNumber) { // && validEmailAddress

      setFormSubmitted(true);
      // On Form submit generate Lead, Contact and Billing Address records
      const personalData = {
        firstName: gContext.goGetRegistrationData().personalData?.firstName,
        lastName: gContext.goGetRegistrationData().personalData?.lastName,
        email: gContext.goGetRegistrationData().personalData?.email,
        phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
        marketingConsent
      };

      const personalDataObj = {
        firstName: gContext.goGetRegistrationData().personalData?.firstName,
        lastName: gContext.goGetRegistrationData().personalData?.lastName,
        email: gContext.goGetRegistrationData().personalData?.email,
        phoneNumber: gContext.goGetRegistrationData().personalData?.phoneNumberValidated,
        marketingConsent
      }

      const payload = {
        ...gContext.goGetRegistrationData(),
        Source: (typeof window !== 'undefined' && sessionStorage.getItem('slug') === 'netomnia') ? 'NETOMNIA' : 'WEBSITE',
        offerId: gContext.goGetRegistrationData()?.offer?.id
      };

      TSAPI.post(
        `/CrmModule/v1.0/db/batch`,
        createLeadContactBillingAddressPayload(payload, personalData)
      )
        .then((response: any) => {

          const leadId = extractLeadIdFromAPIResponse(response.data.data);
          const contactId = extractContactIdFromAPIResponse(response.data.data);
          const addressId = extractAddressIdFromAPIResponse(response.data.data);
          const customerEmail = hex.stringify(sha1(gContext.goGetRegistrationData().personalData.email));
   
          const newState = {
            ...gContext.goGetRegistrationData(),
            leadId,
            contactId,
            addressId,
            personalDataObj,
            checkoutStep: 1,
          };
          if (typeof window !== 'undefined') {
            window.dataLayer.push({
              leadId: leadId,
              customerEmail: customerEmail
            });
          }

          gContext.goSetRegistrationData(newState);

          console.log('%c 2. Create Lead, Contact & Billing address success', 'color:limegreen', response)
          if (typeof window !== 'undefined' && window) {
            localStorage.removeItem('utmData');
          }
          leadContactBillingAddress = response.data.data

          return TSAPI.post(`/CrmModule/v1.0/db-associations/Lead/${leadId}?format=transformLevel2`,
            prepareProductForLeadUpdate(gContext.goGetRegistrationData().selectedProduct || newState)
          )
        })
        .then(response => {
          console.log('%c 3. Adding products to a lead success', 'color:limegreen', response)

          /* 4. Set stage for register interest scenarios */
          if (
            gContext.goGetRegistrationData().searchPremiseResults.properties.SalesStatus === 'REGISTER_INTEREST' ||
            gContext.goGetRegistrationData().searchPremiseResults.properties.SalesStatus === null
          ) {
            return TSAPI.put(`/CrmModule/v1.0/db/Lead/${extractLeadIdFromAPIResponse(leadContactBillingAddress)}`,
              {
                'entity': 'CrmModule:Lead',
                'stageId': OdinProductSchema.stageIds.leadStageId
              }
            )
          }
        })
        .then(() => {
          if (
            gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.SalesStatus === 'ORDER'
          ) {
            navigate("/checkout?s=1", {
              state: { location: 'residential' }
            });
          } else {
            let bsNumbered =
              gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.BuildStatus ?
                gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.BuildStatus.split('-')[0] :
                0;

            bsNumbered = parseInt(bsNumbered)
            let buildNumber = bsNumbered ?? 0;
            let bs = buildNumber > 0 && buildNumber < 5 ? 1 
                     : buildNumber >= 5 && buildNumber < 8 ? 2 
                     : 3;

            navigate(`/success-register-interest?bs=${bs}`);
          }
        })
        .catch((error) => {
          setIsCreatingRecords(false);
          setFormSubmitted(false);
          gContext.setAPIErrorMsg({
            title: 'Error',
            message: error.response ? error.response?.data.message : error
          })
          gContext.setErrorModalVisible(true)
          setPhoneChecked(false)
          setValidPhoneNumber(false)
        });
    }
  },
    [validPhoneNumber]) // , validEmailAddress

  const checkPhone = () => {
    setIsCreatingRecords(true);
    let phoneNumber = gContext.goGetRegistrationData().personalData?.phoneNumber;

    if (isForbiddenNumber(phoneNumber)) { 
      setValidPhoneNumber(false)
      setPhoneChecked(true)
      setIsCreatingRecords(false);
      return
    }

    if (phoneNumber.indexOf('+44') !== -1) {
      phoneNumber = phoneNumber.replace('+44', '')
    }

    API.get(`CrmModule/twilio/v1.0/${'+44' + phoneNumber}/lookup`)
      .then((response) => {
        gContext.goSetRegistrationData({
          ...gContext.goGetRegistrationData(),
          personalData: {
            ...gContext.goGetRegistrationData().personalData,
            phoneNumberValidated: response.data.data.phoneNumber
          }
        })
        setValidPhoneNumber(response.data.data.valid)
        if (!response.data.data.valid) {
          setIsCreatingRecords(false);
        }
        setPhoneChecked(true)
        
      })
      .catch((error) => {
        setValidPhoneNumber(false)
        setPhoneChecked(true)
        setIsCreatingRecords(false);
      })
  }

  const checkEmail = () => {

    API.post('/NotificationModule/v1.0/validation/email', {
      email: gContext.goGetRegistrationData().personalData?.email
    })
      .then((resp) => {
        const result = resp.data.data[0].body.result
        setEmailChecked(true)
        setValidEmailAddress(result == 'Invalid' ? false : true)
      })
      .catch((error) => {
        setValidEmailAddress(false)
        setEmailChecked(true)
        gContext.setAPIErrorMsg({
          title: 'Email validation error',
          message: error.response ? error.response?.data.message : error
        });
      });
  }

  const canNotContinue = () => {
    if (
      !gContext.goGetRegistrationData().personalData?.firstName ||
      !gContext.goGetRegistrationData().personalData?.lastName ||
      !gContext.goGetRegistrationData().personalData?.phoneNumber ||
      !gContext.goGetRegistrationData().personalData?.email ||
      !emailRegex.test(gContext.goGetRegistrationData().personalData?.email)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row className="justify-content-center mt-4">
      <Col sm={12}>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            marketingConsent: false
          }}
          onSubmit={() => {
            setFormSubmitted(true)
            if (canNotContinue()) {
              return
            }
            checkPhone()
            // checkEmail()
          }}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
            <Form>
              <Row>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData().personalData?.firstName && <p className="invalid-label">Required field</p>}
                    <Input
                      disabled={isCreatingRecords && !canNotContinue()}
                      type=""
                      name="firstName"
                      placeholder="First name"
                      autocomplete="given-name"
                      value={gContext.goGetRegistrationData().personalData?.firstName}
                      onChange={e => {
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData().personalData,
                            firstName: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData().personalData?.lastName && <p className="invalid-label">Required field</p>}
                    <Input
                      type=""
                      disabled={isCreatingRecords && !canNotContinue()}
                      name="lastName"
                      placeholder="Last name"
                      autocomplete="family-name"
                      value={gContext.goGetRegistrationData().personalData?.lastName}
                      onChange={e => {
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData().personalData,
                            lastName: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row className='justify-center'>
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !gContext.goGetRegistrationData().personalData?.phoneNumber && <p className="invalid-label">Required field</p>}
                    {formSubmitted && !validPhoneNumber && !canNotContinue() && phoneChecked && <p className="invalid-label">Please enter a valid phone number</p>}
                    <Input
                      type="number"
                      name="phoneNumber"
                      disabled={isCreatingRecords && !canNotContinue()}
                      autocomplete="tel"
                      placeholder="Phone Number"
                      value={gContext.goGetRegistrationData().personalData?.phoneNumber}
                      onChange={e => {
                        setPhoneChecked(false)
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData().personalData,
                            phoneNumber: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>
              <Row>
                {/* Show Quotes Button */}
                <Col sm={12} md={6} lg={4} className="m-auto pb-2">
                  <Box mb={3} className="position-relative">
                    {formSubmitted && !emailRegex.test(gContext.goGetRegistrationData().personalData?.email) && <p className="invalid-label">Required field</p>}
                    {formSubmitted && !validEmailAddress && !canNotContinue() && emailChecked && <p className="invalid-label">Please enter a valid email address</p>}
                    <Input
                      type=""
                      name="email"
                      disabled={isCreatingRecords && !canNotContinue()}
                      autocomplete="email"
                      placeholder="Email"
                      value={gContext.goGetRegistrationData().personalData?.email}
                      onChange={e => {
                        // setEmailChecked(false)
                        gContext.goSetRegistrationData({
                          ...gContext.goGetRegistrationData(),
                          personalData: {
                            ...gContext.goGetRegistrationData().personalData,
                            email: e.target.value
                          }
                        })
                      }}
                      css={isCreatingRecords && !canNotContinue() ? 'opacity: 0.3' : undefined}
                    />
                  </Box>
                </Col>
              </Row>

              <Col sm={12} md={6} lg={4} className="m-auto pt-5">
                <Row >
                  <Col
                    xs={2}
                    lg={2}
                    className="pt-1 text-center text-lg-left"
                  >
                    <TinySwitch setValue={(e: any) => setMarketingConsent(e)} />
                  </Col>
                  <Col xs={10} lg={10} className="pl-5 mb-4">
                    <span className="consent-info register-consent">
                      I am happy to hear from YouFibre by email, WhatsApp and SMS about YouFibre’s broadband packages
                      and any special offers. I am aware that I can opt-out at any time.
                      For details on how we manage your data, please see our&nbsp;
                    </span> 
                    <a
                      target="_blank"
                      href="/legal/privacy-and-cookie-policy"
                      style={{ color: '#08DE9E', textDecoration: 'underline' }}
                    >
                      <span className="consent-info register-consent" style={{ color: '#08DE9E' }}>
                        privacy policy
                      </span>
                    </a>
                    .
                  </Col>
                </Row>
              </Col>

              <Col sm={12} md={4} className="m-auto pt-4">
                <BigRoundButton
                  className={`register-form-button ${(canNotContinue() || isCreatingRecords) ? 'invalid' : ''} ${marketingConsent ? '' : ' grayed-out'}`}
                  title={
                    !canNotContinue() && isCreatingRecords
                      ? 'Please wait...'
                      : gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.SalesStatus ===
                        'ORDER'
                        ? 'Show available packages'
                        : 'Continue'
                  }
                  onClick={handleSubmit}
                  color={marketingConsent ? 'alternative' : 'black'}
                />
              </Col>

              {/* SSL Secure Icon */}
              <Col sm={12} className="text-center mt-3">
                <img
                  src={lockIcon}
                  alt=""
                  className="img-fluid"
                  style={{ maxWidth: '14px', color: 'black' }}
                />
                <span style={{ color: 'black', fontSize: '0.8em', marginLeft: 3 }}>SSL Secure</span>
              </Col>

              {/* Go Back */}
              {
                gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.SalesStatus === 'ORDER' &&
                <Col sm={12} className="text-center mt-5">
                  <p
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      gContext.goSetRegistrationData({
                        ...gContext.goGetRegistrationData(),
                        registerStep: gContext.goGetRegistrationData().currentProvider === 'I_HAVE_NO_ACTIVE_SERVICE' ? 1 : 2
                      })
                    }}
                  >
                    ← Go Back
                  </p>
                </Col>
              }
            </Form>
          )}
        </Formik>
      </Col>
    </Row >
  );
};

export default ResidentialForm;
