import React, { useContext, useEffect, useState } from 'react';
import { Section } from '../components/Core';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalContext from '../context/GlobalContext';
import FullScreenLoader from '../components/FullScreenLoader';
import Seo from '../components/Seo/Seo';
import CurrentProviderForm from '../components/Forms/CurrentProviderForm';
import ResidentialForm from '../components/Forms/ResidentialForm/ResidentialForm';
import CurrentContractForm from '../components/Forms/CurrentContractForm';
import { navigate } from 'gatsby';
import jwt from 'jsonwebtoken';
import API from './../api/api';

/* GTM */
declare global {
  interface Window {
    dataLayer: any[];
  }
}

const RegisterResidentialPage = (props: any) => {
  const gContext: any = useContext(GlobalContext);
  const registrationData = gContext.goGetRegistrationData();

  const [loading, setLoading] = useState(false);
  const [salesStatus, setSalesStatus] = useState("")

  const renderProviderText = (salesStatus) => {
    switch (salesStatus) {
      case 'REG_INTEREST':
        return `<h4>Please enter your details</h4><p>Please enter your details below to check your home eligibility</p>`;
      case 'REG_INTEREST_PLUS':
        return `<h4>Please enter your details</h4><p>Please enter your details below to check your home eligibility</p>`;
      case 'ORDER':
        return `<h4>Final Step</h4>`;
      default:
        return `<h4>Please enter your details</h4><p>Please enter your details below to check your home eligibility</p>`;
    }
  };

  useEffect(() => {

    const sprn = props?.location?.state?.sprn;

    setSalesStatus(registrationData?.searchPremiseResults?.properties?.SalesStatus || 'REGISTER_INTEREST');

    if (sprn) {
      setSalesStatus('ORDER')
      setLoading(true);
      API.get(`/CrmModule/v2.0/premises/${sprn}`)
        .then((response: any) => {
          if (response.data.data._sessionId) {
            const token = response.data.data._sessionId;
            let decoded: any = null;
            try {
              decoded = jwt.verify(token, process.env.GATSBY_JWT_TOKEN);
            } catch (err) {
              decoded = {
                IsBDUK: 'false',
                NetomniaSalesStatus: null,
                SalesStatus: null,
                BuildStatus: 0,
                Providers: null
              };
            }
            response.data.data.properties.NetomniaSalesStatus = decoded.NetomniaSalesStatus;
            response.data.data.properties.SalesStatus = decoded.SalesStatus;
            response.data.data.properties.IsBDUK = decoded.IsBDUK;
            response.data.data.properties.Providers = decoded.Providers;
            response.data.data.properties.BuildStatus = decoded.BuildStatus;
          }
          const skipBooking = response?.data?.data?.properties?.Providers?.indexOf('CITYFIBRE') > -1 ? true : false

          gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            skipBooking,
            searchPremiseResults: response.data.data,
            registerStep: 1
          });

          if (typeof document !== "undefined") {
            if (response.data.data.properties.SalesStatus === 'ORDER') {
              document.cookie = "user_rfs=true";
            }
          }

          setTimeout(() => {
            setLoading(false)
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            setLoading(false);
          }, 1000);

          console.log('%c API Error while Getting Premise information', 'color:red');

          gContext.setAPIErrorMsg({
            title: 'Registration Error',
            message: error.response?.data?.message
              ? error.response?.data?.message
              : 'There was a problem accessing our servers. Please try again later.',
          });
          console.log('%c API Error: ', 'color:red', error);
        });
    } else {
      if (!gContext.goGetRegistrationData()?.searchPremiseResults) {
        navigate('/')
      }
    }

    const params = new URLSearchParams(props.location.search);
    const step = params.get('step');
    gContext.goSetRegistrationData({
      ...gContext.goGetRegistrationData(),
      registerStep: stepGuard(step),
    });
  }, [props.location.search]);

  const stepGuard = (step) => {
    const isRegInterest = (salesStatus == 'REGISTER_INTEREST' || salesStatus == 'REGISTER_INTEREST_PLUS');
    return isRegInterest ? 3 : (step ? parseInt(step) : 1)
  }

  return (
    <>
      <Seo page="register-residential" featuredImageUrl={undefined} />
      <FullScreenLoader title="Processing registration ..." />
      <Section>
        <Container>
          {/* Step 1 - Select Current Provider */}
          {gContext.goGetRegistrationData()?.registerStep === 1 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={6} sm={12} className="text-center mb-3 mt-3">
                  <h4 className="mb-4">Who is your current provider?</h4>
                  <p>If you are unsure, please select "other"</p>
                </Col>
              </Row>
              <CurrentProviderForm />
            </>
          }

          {/* Step 2 - Select Current contract status */}
          {gContext.goGetRegistrationData()?.registerStep === 2 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={6} sm={12} className="text-center mb-3 mt-3">
                  <h4 className="mb-4">Are you still in contract with your current provider?</h4>
                  <p>Tell us more about your contract</p>
                </Col>
              </Row>
              <CurrentContractForm />
            </>
          }

          {/* Step 3 - Provide Personal Information */}
          {gContext.goGetRegistrationData()?.registerStep === 3 &&
            <>
              <Row className="justify-content-center">
                <Col lg={6} md={6} sm={12} className="text-center mt-3">
                  <div
                    className="text-center"
                    dangerouslySetInnerHTML={{
                      __html: renderProviderText(salesStatus),
                    }}
                  />
                </Col>
                {/* Subtitle for orders only */}
                {salesStatus === 'ORDER' && (
                  <Col lg={12} md={6} sm={12} className="text-center mt-3 mb-3">
                    <span>
                      Please enter your details below to view available packages to your home
                    </span>
                  </Col>
                )}
              </Row>
              <ResidentialForm />
            </>
          }
        </Container>
      </Section>
    </>
  );
};

export default RegisterResidentialPage;
